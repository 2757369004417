<template>
  <v-dropdown
    v-bind="$attrs"
    ref="popover"
    placement="bottom-start"
    :data-test-id="dataTestId"
    class="yb-input-border select-none"
    @show="show"
    @hide="hide"
  >
    <resize-observer @notify="resize" />
    <slot />
    <template #popper="{ hide }">
      <div ref="dropdown" class="border-solid border border-yb-gray-lightest dark:border-yb-gray-alt-lightest-inverse yb-body-color shadow-lg" :style="dropdownStyle" :data-test-id="dataTestIdFor('popup')">
        <yb-search-input
          v-show="showSearch"
          v-model="optionsSearch"
          :placeholder="'Search ' + (searchLabel || label)"
          wrapper-classes="p-1 m-2"
          input-classes="pl-2 py-2 pr-4 w-full border rounded text-sm yb-input"
          :data-test-id="dataTestIdFor('input')"
        />

        <div class="list-wrapper max-h-80 overflow-auto select-none">
          <ul class="list-none" :data-test-id="dataTestIdFor('list')">
            <template v-if="allowNull">
              <template v-if="!optionsSearch">
                <li :class="listClasses" @click="handleInput(null, hide)">
                  <slot v-if="!nullLabelSlotEmpty" name="nullLabel" />
                  <template v-else>
                    {{ nullLabel }}
                  </template>
                </li>
              </template>

              <hr v-if="!optionsSearch" class="mx-4 my-2">
            </template>

            <template v-if="optionsSearchResults.length < 100">
              <li
                v-for="(option, index) in optionsSearchResults"
                :key="index"
                :class="listClass(modelValue == option[itemId])"
                @click="handleInput(option, hide)"
              >
                <slot v-if="!!$slots.item" name="item" :option="option" :data-test-id="dataTestIdFor('item')" />
                <component v-bind="{ option, dataTestId: dataTestIdFor('item') }" :is="option.component" v-else-if="option.component" :truncate="truncate" />
                <span v-else class="whitespace-nowrap" :data-test-id="dataTestIdFor('item') + '-' + option[itemId]">{{ option[itemLabel] }}</span>
              </li>
            </template>

            <recycle-scroller
              v-else
              v-slot="{ item: option }"
              class="h-full w-auto select-none scroller"
              :items="optionsSearchResults"
              :item-size="32"
              :key-field="itemId"
            >
              <li
                :class="listClass(modelValue == option[itemId])"
                class="w-full"
                @click="handleInput(option, hide)"
              >
                <slot v-if="!!$slots.item" name="item" :option="option" :data-test-id="dataTestIdFor('item')" />
                <component v-bind="{ option, dataTestId: dataTestIdFor('item') }" :is="option.component" v-else-if="option.component" />
                <span v-else class="whitespace-nowrap" :data-test-id="dataTestIdFor('item') + '-' + option[itemId]">{{ option[itemLabel] }}</span>
              </li>
            </recycle-scroller>
          </ul>
        </div>
        <div v-show="remaining > 0" class="p-2 text-center w-full">
          <a ref="link" class="text-sm cursor-pointer yb-link" :data-test-id="dataTestIdFor('more')" @click="clickall">{{ remaining }} more...</a>
        </div>
      </div>
    </template>
  </v-dropdown>
</template>

<script>
import { isEmpty } from '../composables/emptySlot'
import { ResizeObserver } from './vue-resize'

export default {
  components: {
    ResizeObserver
  },
  props: {
    label: {
      type: String,
      required: false
    },
    searchLabel: {
      type: String,
      required: false
    },
    itemId: {
      type: String,
      required: true
    },
    itemLabel: {
      type: String,
      required: true
    },
    truncate: {
      type: Number,
      default: 0
    },
    openClickArrow: Boolean,
    inputClasses: String,
    options: Array,
    modelValue: [String, Number],
    searchLimit: {
      type: Number,
      default: 10
    },
    listClasses: {
      type: String,
      default: 'px-4 leading-8 flex justify-between text-sm'
    },
    allowNull: Boolean,
    nullLabel: {
      type: String,
      required: false,
      default: '(No Selection)'
    },
    dataTestId: String
  },
  data() {
    const nullLabelSlotEmpty = isEmpty(this.$slots.nullLabel, this.$props)
    return {
      optionsSearch: '',
      all: false,
      width: 0,
      nullLabelSlotEmpty
    }
  },
  computed: {
    showSearch() {
      return this.options && this.options.length > this.searchLimit
    },
    optionsSearchResults() {
      const match = new RegExp(this.optionsSearch, 'i')
      let result = this.optionsSearch.length === 0
        ? this.options
        : this.options
          .filter(option => option[this.itemLabel]?.match(match))
      if (!this.all && this.searchLimit > 0) {
        result = [].concat(...result)
        result.splice(this.searchLimit, result.length - this.searchLimit)
      }
      return result
    },
    remaining() {
      return this.options.length - this.optionsSearchResults.length
    },
    dropdownStyle() {
      let { width } = this
      if (width < 150) {
        width = 150
      }
      const result = { minWidth: `${width}px` }
      false && console.log('Returning style', result)
      return result
    }
  },
  watch: {
    optionsSearch(_) {
      if (_) {
        this.all = false
      }
    },
    options(n, o) {
      if (n?.length !== o?.length) {
        this.all = false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.width = this.$el?.offsetWidth
    })
  },
  methods: {
    show($event) {
      this.$emit('show', $event)
      if (this.showSearch) {
        window.setTimeout(() => {
          const input = this.$refs.dropdown?.querySelector('input')
          false && console.log('input', input)
          input?.focus()
        }, 500)
      }
    },
    hide($event) {
      this.$emit('hide', $event)
    },
    handleInput(option, hide) {
      this.$emit('update:modelValue', !option ? null : option[this.itemId])
      if (typeof hide === 'function') {
        hide()
      }
    },
    clickall() {
      this.all = true
      this.optionsSearch = ''
    },
    listClass(active) {
      let result = this.listClasses
      if (active) {
        if (result) {
          result += ' '
        }
        result += 'isActive'
      }
      return result
    },
    resize() {
      this.width = this.$el?.offsetWidth
    },
    dataTestIdFor(thing) {
      if (this.dataTestId) {
        return this.dataTestId + '-' + thing
      } else {
        return null
      }
    },
    next() {
      const { optionsSearchResults } = this
      const selected = !this.modelValue ? 0 : optionsSearchResults.findIndex(option => this.modelValue === option[this.itemId])
      if (selected >= 0 && selected < optionsSearchResults.length - 1) {
        this.$emit('update:modelValue', optionsSearchResults[selected + 1][this.itemId])
      }
    },
    previous() {
      const { optionsSearchResults } = this
      const selected = optionsSearchResults.findIndex(option => this.modelValue === option[this.itemId])
      if (selected > 0) {
        this.$emit('update:modelValue', optionsSearchResults[selected - 1][this.itemId])
      }
    }
  }
}
</script>

<style scoped lang="postcss">

.list-wrapper li {
  border-left: solid 3px transparent;
  cursor: pointer;
}
.list-wrapper li.isActive {
  @apply bg-yb-rollover dark:text-white dark:bg-yb-gray-dark;
  & a {
    @apply dark:text-white
  }
  & a:hover {
    @apply dark:bg-yb-gray-dark
  }
}

.list-wrapper li:hover:not(.isActive) {
  @apply bg-yb-rollover-light dark:bg-yb-gray-mediumer;
  & a:hover {
    @apply dark:bg-yb-gray-mediumer dark:text-white
  }
}

.list-wrapper li.isActive {
  border-left: solid 3px rgba(134, 188, 77, 1);
}

.v-popover :deep(.trigger) {
  @apply w-full;
}
</style>
