<template>
  <div class="flex flex-col" :class="widthClass">
    <div v-if="optional" class="text-right text-xs italic opacity-75">
      Optional
    </div>
    <div class="flex flex-row items-center">
      <yb-drop-down-search
        ref="search"
        class="relative cursor-pointer w-full"
        :class="classes"
        :options="resolvedOptions"
        :label="searchLabel || label"
        :model-value="modelValue"
        :item-label="itemLabel"
        :truncate="truncate"
        :item-id="itemId"
        :disabled="isDisabled"
        :search-limit="searchLimit"
        :allow-null="allowNull"
        :null-label="nullLabel"
        :list-classes="listClasses"
        :data-test-id="dataTestId"
        @update:model-value="update"
        @hide="hide"
      >
        <template v-if="!nullLabelSlotEmpty" #nullLabel>
          <slot name="nullLabel" />
        </template>

        <label v-if="label" class="mt-1 absolute px-2 left-2 transition-all select-none dark:rounded whitespace-nowrap" :class="labelClasses">{{ label }}</label>
        <button
          ref="openButton"
          type="button"
          :class="buttonClasses"
          tabindex="0"
          @keyup.esc="eventHide"
          @keyup.down="eventDown"
          @keyup.up="eventUp"
          :data-test-id="dataTestId + '-button'"
        >
          <span v-if="(!!modelValue || modelValue === 0) && resolvedOptions.length > 0 && !!label" class="truncate w-10/12" :class="buttonLabelClasses">{{ formatValueLabel(valueLabel) }}</span>
          <slot v-else-if="!label" name="label" />
          <span v-else />
          <div class="flex items-center w-4 h-4">
            <yb-icon class="yb-button-icon-sm w-2 stroke-0 fill-current" icon="nav-arrow-down" />
          </div>
        </button>
      </yb-drop-down-search>
      <div v-if="help" class="w-12 mt-2 flex flex-row justify-center items-center flex-grow-0 flex-shrink-0">
        <yb-icon v-tooltip="{content: help, placement: 'bottom'}" class="h-5 w-5 cursor-pointer" icon="regular-question" />
      </div>
    </div>
    <p v-if="validation && validation.$dirty && validation.$invalid && validation.required && validation.required.$invalid" class="text-left yb-error-text text-xs mt-0 mb-4 h-0">
      This field is required.
    </p>
  </div>
</template>
<script>

export default {
  props: {
    options: [Array, Promise],
    label: String,
    searchLabel: String,
    modelValue: [String, Number],
    itemId: {
      type: String,
      required: true
    },
    itemLabel: {
      type: String,
      required: true
    },
    disabled: Boolean,
    searchLimit: {
      type: Number,
      default: 5
    },
    allowNull: Boolean,
    nullLabel: {
      type: String,
      required: false,
      default: '(No Selection)'
    },
    validation: {
      type: Object
    },
    searchClasses: {
      type: String,
      default: 'flex-grow'
    },
    dataTestId: String,
    optional: Boolean,
    help: {
      type: String,
      required: false
    },
    widthClass: {
      type: String,
      default: 'w-full'
    },
    heightClass: {
      type: String,
      default: 'h-12'
    },
    truncate: {
      type: Number,
      default: 0
    },
    inputClass: {
      type: String,
      default: 'text-left yb-input focusable dark:rounded'
    },
    buttonLabelClass: {
      type: String,
      default: 'text-sm'
    },
    listClasses: {
      type: String
    },
    customLabelClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    const nullLabelSlotEmpty = isEmpty(this.$slots.nullLabel, this.$props)
    return {
      resolvedOptions: [],
      nullLabelSlotEmpty
    }
  },
  computed: {
    labelClasses() {
      const offsetPosition = this.optional ? '-top-1.5' : '-top-2'
      return (!!this.modelValue || this.modelValue === 0) && this.resolvedOptions.length > 0 ? `${offsetPosition} select-label select-label-has-value yb-body-background text-xs font-light ${this.customLabelClasses}` : `select-label select-label-no-value top-1 text-sm leading-10 font-light opacity-75 ${this.customLabelClasses}`
    },
    buttonLabelClasses() {
      return this.buttonLabelClass
    },
    isDisabled() {
      return this.disabled || !this.resolvedOptions || this.resolvedOptions.length === 0
    },
    valueLabel() {
      const option = this.resolvedOptions.find(o => o[this.itemId] === this.modelValue)
      return option ? option[this.itemLabel] : this.modelValue
    },
    classes() {
      let result = this.searchClasses
      if (this.isDisabled) {
        if (result) {
          result += ' '
        }
        result += 'pointer-events-none opacity-50'
      }
      return result
    },
    buttonClasses() {
      const result = [this.heightClass, this.inputClass]
      if (!!this.validation && !!this.validation.$dirty && !!this.validation.$invalid) {
        result.push('border-red-200')
      }
      return result
    }
  },
  watch: {
    options(_) {
      this.resolveOptions()
    }
  },
  async mounted() {
    this.resolveOptions()
  },
  methods: {
    hide($event) {
      this.$emit('blur', $event)
      if (this.validation) {
        this.validation.$touch()
      }
    },
    formatValueLabel(label) {
      if (!label || typeof label !== 'string') {
        return label
      }
      if (!this.truncate || label.length <= this.truncate) {
        return label
      }
      return label.substring(0, this.truncate) + '...'
    },
    show() {
      this.$refs.openButton.click()
    },
    eventHide() {
      this.$refs.search.open = false
    },
    eventDown() {
      if (!this.$refs.search.open) {
        this.$refs.search.open = true
      } else {
        this.$refs.search.next()
      }
    },
    eventUp() {
      if (this.$refs.search.open) {
        this.$refs.search.open = false
      } else {
        this.$refs.search.previous()
      }
    },
    async resolveOptions() {
      this.resolvedOptions = await Promise.resolve(this.options)
    },
    update($event) {
      this.$emit('update:modelValue', $event)
      if (this.validation) {
        this.validation.$touch()
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.yb-input {
  @apply flex items-center justify-between w-full py-1 px-4 dark:bg-yb-gray-alt-darker;
}
</style>
